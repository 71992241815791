import React from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { getSessionName, save_data, socket_server } from "../../../shared/functions";
import { useAppDispatch, useAppSelector } from "../../../shared/hooks";
import { db_store } from "../../../shared/db";

const TextAreaInput = React.memo((props: any) => {
    const textAreaRef = React.useRef<any>();
    const text_editor_is_opened = useAppSelector(state => state.main.show_text_editor);
    const dispatch = useAppDispatch()
    const room_id = useAppSelector(state => state.main.room_name);
    

    const setData = React.useCallback(() => {
        if (textAreaRef.current?.getData() != '') {
            save_data({
                data: textAreaRef.current!.getData(),
                type: 'text',
            }, async (e: any) => {
                textAreaRef.current!.setData('');
                db_store.main.get(e).then((data: any) => {
                    socket_server.emit('route-data', null, {room_name: room_id, texts: data, type: "main"});
                })
            });
        }
    }, [textAreaRef.current, room_id]);

    return (
        text_editor_is_opened ?
        <div className="w-100 px-4">
            <div className="main-editor">
                <CKEditor
                    editor={ Editor }
                    
                    config={{
                        // plugins: [ Style],
                        // toolbar: [ 'bold', 'italic' ]
                        removePlugins: ['Style',]
                    }}
                    onReady={editor => textAreaRef.current = editor}
                />

                <div className="m-0 p-0 d-flex justify-content-end">
                    <button type="button" onClick={setData} className="text-btn">Send</button>
                </div>

            </div>
        </div> :
        <div></div>
    )
})

export default TextAreaInput;