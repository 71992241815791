import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBBtn } from 'mdb-react-ui-kit';
import Icons from '../../../shared/icons';
import { useAppDispatch } from '../../../shared/hooks';
import { setShowInfoModal } from '../../../shared/rdx-slice';

const Footer = React.memo(() => {
    const dispatch = useAppDispatch();

    return (
        <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
            <div className='me-5'>
            <span>QuickShare</span>
            </div>

            <div>
                <MDBBtn type='button' 
                    onClick={() => dispatch(setShowInfoModal(true))}
                    className='btn-outline-info quick-info-btn shadow' 
                    outline>
                    <Icons.Info className="mx-2" /> Quick Info
                </MDBBtn>
            </div>
        </section>

        <section className='d-flex justify-content-center p-4 border-bottom'>
            <div>
                <a href='https://pryxy.com'>Take me to Pryxy homepage</a>
            </div>
        </section>


        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
            © {new Date().getFullYear()} Copyright: {' '}
            <a className='text-reset fw-bold' href='https://pryxy.com/'>
            Pryxy
            </a>
        </div>
        </MDBFooter>
    );
})

export default Footer;