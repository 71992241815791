import React, { useState } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from 'mdb-react-ui-kit';
import { useAppDispatch, useAppSelector } from '../../shared/hooks';
import { setShowInfoModal } from '../../shared/rdx-slice';

const InfoModal = React.memo(() => {
  const show_modal = useAppSelector(state => state.main.show_info_modal)
  const dispatch = useAppDispatch()

  return (
    <>
      <MDBModal open={show_modal} setOpen={(state: boolean) => dispatch(setShowInfoModal(state))} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent className='modal-content-cont'>
            <MDBModalHeader className='border-bottom-0'>
              <MDBModalTitle>Quick Info</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={() => dispatch(setShowInfoModal(false))}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <div className='mb-3'>
                    <b>QuickShare</b> is a real-time content sharing application. Share texts and files instantly. 
                    Collaborate with others in real-time making it easy to work together 
                    on projects, documents, and ideas. <br />
                </div>

                <div className='mb-3'>
                    <b>Note on data transfer:</b> QuickShare doesn't track/access/save any data that is trasferred between clients
                    in any storage system or database and all data are transferred directly from client to clients in a room through a 2-way
                    direct client-server connection technology called WebSockets. <br />
                    The data shared by and to the host is saved
                    on the host's browser in a secured browser storage called IndexedDB and it is shared to the
                    connected clients. Selected files are not saved in the storage and selected files are gone after 
                    the host refreshes or closes the browser.
                </div>

                <div>
                    <b>Transferred Files:</b> Currently QuickShare only supports maximum file size of 5mb, I am 
                    currently working on increasing this limit please bare with me, so please keep the file size to 
                    maximum of 5mb for the best user experience. <br />
                    And it also depends on the network speed of both the host and the connected clients,
                    the timeout before the clients gets disconnected after trying to send/receive large files under slow network 
                    varies between 30 to 50 seconds and after this the client (either the host or the connected clients) will 
                    try to reconnect to the room automatically and the exchange of data that was sent while the client 
                    was disconnected will happen automatically after the reconnection but this doesn't apply to the host 
                    because data sent while the host is disconnected are lost and will not be resent to the host on 
                    reconnection, when this happens and you're the host please remove the large file, 
                    this issue will be resolved in future releases.
                </div>


            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
})

export default InfoModal;