import { toast } from "react-toastify";
import { session_name } from "./constants";
import { db_store } from "./db";
import { clearRoomMembers, setIsViewOwner, setRoomName, updateRemoteFiles, updateRemoteSubPostData, updateRemoteTextData, updateRoomMembers } from "./rdx-slice";
import { store } from "./store";
import { IMainDB, ISubPostTable } from "./types";
// @ts-ignore
import { saveAs } from 'file-saver';
import { io } from "socket.io-client";


export const createSessionName = (len=8): string => {
    let chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
    const cryptoIsAvailable = window.crypto;

    const getRandChars = (chars: string, len: number) => {
        let rand_c = '';
        const chars_1 = cryptoIsAvailable ? crypto.randomUUID().replace(/\-/g,'') + chars.slice(chars.length / 2) : chars;
        for (let i = 0; i < len; i++) {
            rand_c += chars_1[Math.floor(Math.random() * chars_1.length)];
        }
        return rand_c;
    }

    const rand_1 = getRandChars(chars, 20);
    const rand_2 = getRandChars(rand_1, 15);
    return getRandChars(rand_2, len);
}

export const setSessionName = () => {
    localStorage.getItem(session_name) == null && localStorage.setItem(session_name, createSessionName());
}

export const getSessionName = (): string | null => {
    if (localStorage.getItem(session_name) == null) {
        setSessionName()
        return localStorage.getItem(session_name);
    }
    return localStorage.getItem(session_name);
}

export const getViewSessionNameFromURL = () => {
    const queryParams = window.location.search;
    const regexp = /sid\=(\w+)/;
    const execRegexp = regexp.exec(queryParams);
    return execRegexp?.[1] ?? '';
}

export const isUserViewOwner = () => {
    store.dispatch(setRoomName(getViewSessionNameFromURL() || getSessionName() as string));
    const isOwner = getViewSessionNameFromURL() == '' || getViewSessionNameFromURL() == getSessionName();
    store.dispatch(setIsViewOwner(isOwner));
}

export const save_data = async (data: IMainDB, callbackFn?: Function) => {
    return db_store.main.add({
        data: data.data,
        type: data.type,
        date: new Date().getTime(),
        files: data.files
    }).then((e: any) => {
        callbackFn && callbackFn(e)
    })
}

export const save_post_comment = async (data: ISubPostTable, callbackFn?: Function) => {
    return db_store.sub_post.add({
        data: data.data,
        type: 'text',
        date: new Date().getTime(),
        post_id: data.post_id
    }).then((e: any) => {
        callbackFn && callbackFn(e)
    })
}

export const convertFileSize = (fileSizeInBytes: number) => {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
    let size = fileSizeInBytes;
    let unitIndex = 0;
  
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
  
    return `${size.toFixed(2)} ${units[unitIndex]}`;
}

export const download_file = (file: File) => {
    saveAs(file, file.name);
}

export const socket_server = io('wss://api.pryxy.com:7800', {
    query: {
        'p-roomname': getSessionName() as string
    },
});

const joinRoom = () => {
    setTimeout(() => {
        socket_server.emit('join-room', {room_name: store.getState().main.room_name})
    }, 300);
}

const send_handshake = () => {
    db_store.main.orderBy(':id').reverse().toArray().then(async data => {
        const sub_posts = await db_store.sub_post.orderBy(':id').reverse().toArray()
        socket_server.emit('handshake', {
            to: store.getState().main.room_name,
            members: [{member_id: getSessionName(), isOwner: store.getState().main.isViewOwner}, ...store.getState().main.room_members], 
            contents: {files: store.getState().main.selected_files.map(file => ({file: file.file, type: file.file.type, name: file.file.name, id: file.file.id})), texts: data, sub_text: sub_posts}
        })
    })
}

socket_server.on('connect', () => {
    joinRoom();
});

socket_server.on("reconnect", (data: any) => {
    // joinRoom();
});

const addRoomMember = (member: any) => {
    const user_already_added = store.getState().main.room_members.filter(m => m.member_id == member.member_id).length > 0;
    if (!user_already_added) store.dispatch(updateRoomMembers(member));
}

socket_server.on("host-rejoined", (data: any) => {
    if (getSessionName() == data.member_id) return;
    toast("The host has rejoined")
    addRoomMember({member_id: data.member_id, isOwner: data.isOwner})
    socket_server.emit("member-handshake", store.getState().main.room_name, {member_id: getSessionName(), isOwner: false})
});

socket_server.on("member-handshake", (data: any) => {
    if (!store.getState().main.isViewOwner) return;
    addRoomMember(data);
});

socket_server.on("handshake", (data: any) => {
    // store.dispatch(clearRoomMembers());
    store.dispatch(updateRemoteSubPostData({data: [], clear: true})); 
    store.dispatch(updateRemoteFiles({data: [], clear: true}));
    store.dispatch(updateRemoteTextData({data: [], clear: true}));
    
    data.members.forEach((member: any) =>  {
        addRoomMember(member);
    })
    data.contents.texts.reverse().forEach((text: IMainDB) => 
        store.dispatch(updateRemoteTextData({data: text}))      
    )
    data.contents.sub_text.reverse().forEach((text: ISubPostTable) => 
        store.dispatch(updateRemoteSubPostData({data: text}))      
    )
    data.contents.files.forEach((file: any) => {
        const nfile = new File([file.file], file.name, {type: file.type}) 
        // @ts-ignore
        nfile.id = file.id 
        store.dispatch(updateRemoteFiles({data: {file: nfile, bs4: window.URL.createObjectURL(nfile)}}))  
    })
});

socket_server.on("join-notif", (data: any) => {
    toast(data.msg)
    if (store.getState().main.isViewOwner) {
        send_handshake()
        addRoomMember({member_id: data.viewerId});
          
    }else{
        data.isOwner && socket_server.emit("member-handshake", store.getState().main.room_name, {member_id: getSessionName(), isOwner: false});
        if (store.getState().main.remote_text_data.length === 0 && store.getState().main.remote_files.length === 0) {
            socket_server.emit("data-request", {room_name: store.getState().main.room_name});
        }else{
            addRoomMember({member_id: data.viewerId});
        }
          
    };
});

socket_server.on("data-request", (data) => {
    store.getState().main.isViewOwner && send_handshake();
})

socket_server.on("route-data", (files, data) => {
    if (data.texts != undefined && data.type == "main") {
        store.dispatch(updateRemoteTextData({data: data.texts}));
    } else if (data.texts != undefined && data.type == "sub") {
        toast("New Comment: " + data.texts.data.slice(0, 12) + "... Click to View", {
            theme: 'colored',
            onClick(event) {
                document.querySelector('#share-id-'+data.texts.post_id)?.scrollIntoView()
            },            
        })
        if (store.getState().main.isViewOwner) {
            save_post_comment(data.texts);         
        }else{
            store.dispatch(updateRemoteSubPostData({data: data.texts}));  
        }
    }

    files?.forEach((file: any) => {
        const nfile = new File([file.file], file.name, {type: file.type})  
        // @ts-ignore
        nfile.id = file.id
        store.dispatch(updateRemoteFiles({data: {file: nfile, bs4: window.URL.createObjectURL(nfile)}}))  
    });
});

socket_server.on("item-deleted", (data) => {
    if (data.type == 'text') {
        const filtered_text_item = store.getState().main.remote_text_data.filter(text => text.id != data.id)
        store.dispatch(updateRemoteTextData({data: filtered_text_item, clear: true}))      
    }else if (data.type == 'file') {
        const filtered_remote_files = store.getState().main.remote_files.filter(file => file.file.id != data.id)
        store.dispatch(updateRemoteFiles({data: filtered_remote_files, clear: true}))  
    }
});

socket_server.on("user-left", (data: any) => {
    const room_members = store.getState().main.room_members.filter(member => member.member_id != data.id);
    store.dispatch(clearRoomMembers())
    room_members.forEach((member: any) =>  addRoomMember(member))
    if (data.is_host == true) toast("The host has left, data sent will not be persisted and will not be seen by host until host has rejoined but will be shared with other members in the group");
});

socket_server.on("connect_error", () => {});