import './App.css';
import AppWrapper from './structure/AppWrapper';
import { ToastContainer } from 'react-toastify';
import FileSelectUI from './structure/fileSelect/fileSelectUI';
import { useAppSelector } from './shared/hooks';
import FilePreviewer from './structure/extras/FilePreviewer';
import React from 'react';
import { isUserViewOwner, setSessionName } from './shared/functions';
import LinkShare from './structure/extras/LinkShare';
import InfoModal from './structure/extras/InfoModal';

function App() {
  const should_show = useAppSelector(state => state.main.show_file_select);
  const should_show_file_previewer = useAppSelector(state => state.main.show_file_previewer);
  const should_show_link_share = useAppSelector(state => state.main.show_link_share);

  React.useLayoutEffect(() => {
    isUserViewOwner();
    setSessionName();
  }, [])

  return (
    <div className="">
      <AppWrapper />
      <ToastContainer />
      {
        should_show &&
        <FileSelectUI />
      }
      {
        should_show_file_previewer &&
        <FilePreviewer />
      }

      {
        should_show_link_share &&
        <LinkShare />
      }

      <InfoModal />
    </div>
  );
}

export default App;
