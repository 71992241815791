import Dexie, { Table } from 'dexie';
import { IMainDB, ISubPostTable } from './types';

export class IndexedDBHandler extends Dexie {
  main!: Table<IMainDB>;
  sub_post!: Table<ISubPostTable>;

  constructor() {
    super('pryxy_quickshare');
    this.version(2).stores({
      main: '++id, data, type, date, files', // Primary key and indexed props
      sub_post: '++id, data, type, date, post_id'
    });
  }
}

export const db_store = new IndexedDBHandler();
