import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import Header from "./components/layouts/header";
import MainSection from "./components/sections/MainSection";
import SideSection from "./components/sections/SideSection";
import { useAppSelector } from "../shared/hooks";
import {Helmet} from "react-helmet";
import Footer from "./components/layouts/footer";

const AppWrapper = React.memo((props: any) => {
    const isViewOwner = useAppSelector(state => state.main.isViewOwner);
    const room_name = useAppSelector(state => state.main.room_name);

    return (
        <MDBContainer fluid style={{padding: 0, margin: 0,}}>
            <Helmet>
                <meta property="og:site_name" content="QuickShare" />
                <meta property="og:title" content={"Quickshare by Pryxy | Session ID - "+room_name} />
                <meta property="og:image" content={require('../assets/quickshare.png')} />
                <meta property="og:url" content={"https://quickshare.pryxy.com/?sid="+room_name} />
                <meta property="og:description" content="Join this session to view and collaborate on the shared content in real-time" />

                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@paulos_ab" />
                <meta name="twitter:title" content={"Quickshare by Pryxy | Session ID - "+room_name} />
                <meta name="twitter:description" content="Join this session to view and collaborate on the shared content in real-time" />
                <meta name="twitter:image" content={require('../assets/quickshare.png')} />
                <meta name="twitter:image:alt" content="QuickShare | Real-time content sharing. Share texts and files instantly. 
                    Collaborate with others in real-time making it easy to work together on projects, documents, and ideas. No Signup needed!" />
                
            </Helmet>
            <Header />
            <MDBRow className="m-0 gx-0 d-flex justify-content-center" style={{minHeight: '90vh'}}>
                <MainSection />
                {
                    isViewOwner &&
                    <SideSection />
                }
            </MDBRow>
            <Footer />

        </MDBContainer>
    )
})

export default AppWrapper; 