import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { setShowFilePreviewer } from "../../shared/rdx-slice";
import Icons from "../../shared/icons";

const FilePreviewer = React.memo(() => {
    const file_to_preview = useAppSelector(state => state.main.file_to_preview);
    const should_show = useAppSelector(state => state.main.show_file_previewer);

    const dispatch = useAppDispatch();

    React.useLayoutEffect(() => {
        document.onkeydown = (e) => {
            if (e.key.toLowerCase() == 'escape' && should_show) {
                dispatch(setShowFilePreviewer({status: false, file: null}))
            }
        }
    }, [])


    const FilePreview = React.memo(() => {
        if (file_to_preview?.type.split('/')[0] == 'image') {
            return <div><img src={window.URL.createObjectURL(file_to_preview)} /></div>
        }else if (file_to_preview?.type.split('/')[0] == 'video') {
            return <div>
                        <video playsInline={true} muted={false} disablePictureInPicture={true} controls autoPlay>
                            <source src={window.URL.createObjectURL(file_to_preview)} type="video/mp4" />
                            <source src={window.URL.createObjectURL(file_to_preview)} type="video/webm" />
                            <source src={window.URL.createObjectURL(file_to_preview)} type="video/ogg" />
                        </video>
                    </div>   
        }else if (file_to_preview?.type.split('/')[0] == 'audio') {
            return <div>
                        <audio controls>
                            <source src={window.URL.createObjectURL(file_to_preview)} type="audio/mpeg" />
                            <source src={window.URL.createObjectURL(file_to_preview)} type="audio/ogg" />
                            <source src={window.URL.createObjectURL(file_to_preview)} type="audio/wav" />
                            Your browser does not support the audio tag.
                        </audio>
                    </div>   
        }else{
            return <div className="">
                        <iframe
                            src={window.URL.createObjectURL(file_to_preview as Blob)}
                            title="iframe Example 1"
                            width="100%"
                            height="600">
                        </iframe>

                    </div>
        }
    })

    return (
        <MDBContainer fluid className="file-preview-container d-flex align-items-center justify-content-center">
            <div className="file-select-close-btn" onClick={() => dispatch(setShowFilePreviewer({status: false, file: null}))}>
                <Icons.Close />
            </div>
            <MDBRow className="w-100 d-flex align-items-center justify-content-center">
                <MDBCol xs={12} sm={12} md={8} lg={6}>
                    <FilePreview />
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    )
})

export default FilePreviewer;