import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import icons from "../../../shared/icons";
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { setShowFileSelectUI, setShowLinkShare, setShowTextEditor } from '../../../shared/rdx-slice';


const Header = React.memo(() => {
    const text_editor_is_opened = useAppSelector(state => state.main.show_text_editor);
    const dispatch = useAppDispatch()
    const isViewOwner = useAppSelector(state => state.main.isViewOwner)
    const should_show_link_share = useAppSelector(state => state.main.show_link_share)
    const room_members = useAppSelector(state => state.main.room_members)

    const openFileSelect = React.useCallback(() => {
        dispatch(setShowFileSelectUI(true));
        window.screen.width <= 576 && dispatch(setShowTextEditor(false));
    }, [])

    const openTextEditor = React.useCallback(() => {
        dispatch(setShowTextEditor(!text_editor_is_opened));
    }, [text_editor_is_opened])

    const showLinkShareContainer = React.useCallback(() => {
        dispatch(setShowLinkShare(!should_show_link_share));
    }, [should_show_link_share])

    return (
        <MDBNavbar expand='xs' light style={{backgroundColor: 'transparent', boxShadow: 'none', position: 'sticky', top: 0, zIndex: +9}}>
            <MDBContainer fluid >
                <MDBNavbarBrand href='#' style={{fontWeight: '700'}}>QuickShare</MDBNavbarBrand>
                
                <MDBCollapse  open={true}>
                    <MDBNavbarNav className='mb-2 mb-lg-0'>
                    </MDBNavbarNav>

                    <div className='header-utils d-flex' style={{margin: '0px 10px'}}>
                        {
                            <MDBBtn className='d-flex mx-2 align-items-center joined-members-btn'>
                                <icons.Members className="write-pen" /> 
                                <div className='mx-2'>{isViewOwner ? room_members.length + 1 : room_members.length}</div>
                            </MDBBtn>
                        }

                        <MDBBtn className='d-flex mx-2 align-items-center' onClick={showLinkShareContainer}>
                            <icons.Share className="share-icon" /> 
                            <div className='px-2 d-none d-md-flex'>Share Page</div>                
                        </MDBBtn>
                        
                        {
                            isViewOwner &&
                            <MDBBtn className='d-flex d-xs-flex d-lg-none mx-2 align-items-center send-text-btn' onClick={openTextEditor}>
                                <icons.Write className="write-pen" /> 
                                <div className='px-2 d-none d-md-flex'>{!text_editor_is_opened ? 'Write Something' : 'Hide Editor'}</div>                
                            </MDBBtn>
                        }

                        {
                            isViewOwner &&
                            <MDBBtn className='d-flex align-items-center' onClick={openFileSelect}>
                                <icons.AddFile /> 
                                <div className='px-2 d-none d-md-flex'>Select Files</div>                
                            </MDBBtn>
                        }
                    </div>
                </MDBCollapse>
            </MDBContainer>
        </MDBNavbar>
    );
})

export default Header;