import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IMainDB, IMainState, ISubPostTable, TRoomMember, TSelectedFile } from './types'



// Define the initial state using that type
const initialState: IMainState = {
    isViewOwner: true,
    selected_files: [],
    show_file_select: false,
    show_file_previewer: false,
    file_to_preview: null,
    show_text_editor: window.screen.width > 768,
    room_name: '',
    room_members: [],
    remote_text_data: [],
    remote_files: [],
    show_link_share: false,
    remote_sub_post_data: [],
    show_info_modal: false
}

export const mainSlice = createSlice({
  name: 'main',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsViewOwner: (state, action: PayloadAction<boolean>) => {
      state.isViewOwner = action.payload
    },
    updateSelectedFiles: (state, action: PayloadAction<TSelectedFile>) => {
      state.selected_files = [action.payload, ...state.selected_files];
    },
    setShowFileSelectUI: (state, action: PayloadAction<boolean>) => {
      state.show_file_select = action.payload
    },
    clearSelectedFiles: (state) => {
      state.selected_files = []
    },
    setShowFilePreviewer: (state, action: PayloadAction<{status: boolean; file: File | null}>) => {
        state.show_file_previewer = action.payload.status;
        state.file_to_preview = action.payload.file;
    },
    setShowTextEditor: (state, action: PayloadAction<boolean>) => {
        state.show_text_editor = action.payload;
    },
    setRoomName: (state, action: PayloadAction<string>) => {
        state.room_name = action.payload;
    },
    updateRoomMembers: (state, action: PayloadAction<TRoomMember>) => {
        state.room_members.push(action.payload);
    },
    clearRoomMembers: (state) => {
        state.room_members = [];
    },
    updateRemoteTextData: (state, action: PayloadAction<{data: IMainDB | Array<IMainDB>; clear?: boolean}>) => {
        if (action.payload.clear) {
            state.remote_text_data = [];
            state.remote_text_data = action.payload.data as IMainDB[];
        }else{
            state.remote_text_data = [action.payload.data as IMainDB, ...state.remote_text_data];
        }
    },
    updateRemoteSubPostData: (state, action: PayloadAction<{data: ISubPostTable | Array<ISubPostTable>; clear?: boolean}>) => {
        if (action.payload.clear) {
            state.remote_sub_post_data = [];
            state.remote_sub_post_data = action.payload.data as ISubPostTable[];
        }else{
            state.remote_sub_post_data = [action.payload.data as ISubPostTable, ...state.remote_sub_post_data];
        }
    },
    updateRemoteFiles: (state, action: PayloadAction<{data: Array<TSelectedFile> | TSelectedFile; clear?: boolean}>) => {
        if (action.payload.clear) {
            state.remote_files = [];
            state.remote_files = action.payload.data as TSelectedFile[];
        }else{
            state.remote_files = [action.payload.data as TSelectedFile, ...state.remote_files];
        }
    },
    setShowLinkShare: (state, action: PayloadAction<boolean>) => {
        state.show_link_share = action.payload
    },
    setShowInfoModal: (state, action: PayloadAction<boolean>) => {
        state.show_info_modal = action.payload
    },
  },
})

export const { 
    setIsViewOwner,
    updateSelectedFiles,
    setShowFileSelectUI,
    clearSelectedFiles,
    setShowFilePreviewer,
    setShowTextEditor,
    setRoomName,
    clearRoomMembers,
    updateRoomMembers,
    updateRemoteTextData,
    updateRemoteSubPostData,
    updateRemoteFiles,
    setShowLinkShare,
    setShowInfoModal
} = mainSlice.actions

export default mainSlice.reducer