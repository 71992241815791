import React from "react";
import icons from "../../../shared/icons";
import Markdown from 'markdown-to-jsx'
import SyntaxHighlighter from "../../../shared/syntax-highlighter";
import { useAppDispatch, useAppSelector } from "../../../shared/hooks";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { getSessionName, save_post_comment, socket_server } from "../../../shared/functions";
import { ISubPostTable } from "../../../shared/types";
import { db_store } from "../../../shared/db";
import { updateRemoteSubPostData } from "../../../shared/rdx-slice";

const TextShareItem = React.memo((props: any) => {
    const ref = React.useRef<HTMLDivElement | null>(null)
    const isViewOwner = useAppSelector(state => state.main.isViewOwner);
    const room_id = useAppSelector(state => state.main.room_name);
    const [toggleCommentEditor, setToggleCommentEditor] = React.useState<boolean>(false)
    const sub_post_editor_ref = React.useRef<any>();
    const dispatch = useAppDispatch();

    const handleShowCommentEditor = React.useCallback(() => {
        setToggleCommentEditor(!toggleCommentEditor);
    }, [toggleCommentEditor]);

    const handleSavePostComment = React.useCallback(() => {
        if(sub_post_editor_ref.current!.getData() == '') return;
        if (isViewOwner) {
            save_post_comment({
                data: sub_post_editor_ref.current!.getData(),
                type: 'text',
                post_id: props.content.id
            }, (e: any) => {
                sub_post_editor_ref.current!.setData('');
                db_store.sub_post.get(e).then((data: any) => {
                    socket_server.emit('route-data', null, {room_name: room_id, texts: data, type: "sub"});
                })
            });
        }else{
            const data = {
                data: sub_post_editor_ref.current!.getData(),
                type: 'text',
                post_id: props.content.id,
                date: new Date().getTime(),
            } as ISubPostTable
            socket_server.emit('route-data', null, {room_name: room_id, texts: data, type: "sub"});
            dispatch(updateRemoteSubPostData({data}));     
            sub_post_editor_ref.current!.setData('');
        }
        
    }, [sub_post_editor_ref.current, room_id]);

    return (
        <div ref={ref} id={"share-id-"+props.content.id} className="text-share-item" key={Math.floor(Math.random() * 99999999999999)}>
            <div className="text-share-item-utils-container">
                <icons.Copy title="Copy" onClick={() => props.copy(ref.current, ".main-content")} />
                {
                    isViewOwner && <icons.Trash onClick={(e:any) => props.delete(props.content.id)} title="Delete" />
                }
                <icons.Comment title="Comment" onClick={handleShowCommentEditor} />
            </div>
            
            <div className="main-content">
                <Markdown
                    children={props.content.data}
                    options={{
                        overrides: {
                            code: SyntaxHighlighter,
                        },
                    }}
                />
            </div>

            <div className={toggleCommentEditor ? "sub-post-editor-container mt-3" : "sub-post-editor-container d-none"}>
                <CKEditor
                    editor={ Editor }
                    config={{
                        removePlugins: ['Style',]
                    }}
                    onReady={editor => sub_post_editor_ref.current = editor}
                />

                <div className="m-0 p-0 d-flex justify-content-end">
                    <button type="button" onClick={handleSavePostComment} className="text-btn">Send</button>
                </div>

            </div>

            <div className="d-flex justify-content-end flex-shrink-0 flex-wrap">
                {
                    props.sub_post?.map((post: any) => 
                        post.post_id == props.content.id ? 
                        <div className="w-75 p-4 bg-light mt-3 shadow rounded mb-2 child-text-share-item" key={Math.floor(Math.random() * 9999999999)}>
                            <div className="child-share-inner">
                                <Markdown
                                    children={post.data}
                                    options={{
                                        overrides: {
                                            code: SyntaxHighlighter,
                                        },
                                    }}
                                />
                            </div>

                            <div className="copy-container" onClick={(e: any) => props.copy(e.currentTarget.parentElement, '.child-share-inner')}>
                                Click to Copy
                            </div>
                        </div> : ''
                    )
                }

            </div>

        </div>
    )
})

export default TextShareItem;