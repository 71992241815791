import { MDBCol } from "mdb-react-ui-kit";
import React from "react";
import TextAreaInput from "../sideSectionComponents/TextAreaInput";

const SideSection = React.memo((props: any) => {
    return (
        <MDBCol xs={12} lg={4} className="order-1 order-lg-2 mb-3">
            <TextAreaInput />
        </MDBCol>
    )
})

export default SideSection;