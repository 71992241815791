import React from "react";
import hljs from 'highlight.js';

const SyntaxHighlighter = (props: any) => {
    const ref = React.useRef <HTMLElement | null> (null)
  
    React.useEffect(() => {
        if (ref.current && props.className?.includes('lang-') && hljs) {
            hljs.highlightAll()
            ref.current.removeAttribute('data-highlighted')
        }
    }, [props.className, props.children, ref.current])
  
    return <pre><code {...props} ref={ref} /></pre>
};

export default SyntaxHighlighter;