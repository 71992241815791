import { MDBCol } from "mdb-react-ui-kit";
import React from "react";
import TextShareItem from "../mainSubComponents/TextShareItem";
import { useLiveQuery } from "dexie-react-hooks";
import { db_store } from "../../../shared/db";
import { toast } from 'react-toastify';
import { useAppSelector } from "../../../shared/hooks";
import Icons from "../../../shared/icons";
import { convertFileSize, socket_server } from "../../../shared/functions";
import SelectedFilesItem from "../../extras/SelectedFilesItem";
import Illustration from "../../../shared/illustration";

const MainSection = React.memo((props: any) => {
    const data = useLiveQuery(() => db_store.main.orderBy(':id').reverse().toArray());
    const sub_post = useLiveQuery(() => db_store.sub_post.orderBy(':id').reverse().toArray());
    const isViewOwner = useAppSelector(state => state.main.isViewOwner);
    const selected_files = useAppSelector(state => state.main.selected_files);
    const remote_text_data = useAppSelector(state => state.main.remote_text_data);
    const remote_sub_post_data = useAppSelector(state => state.main.remote_sub_post_data);
    const remote_files = useAppSelector(state => state.main.remote_files);

    const deleteItem = React.useCallback((id: number) => {
        db_store.main.where({id}).delete()
        .then(res => {
            toast("Item deleted")
            db_store.sub_post.where({post_id: id}).delete()
            socket_server.emit("item-deleted", {type: 'text', id})
        })
    }, []);

    const copyItem = React.useCallback((container: any, cssSelector?: string) => {
        const targetContainer = cssSelector != undefined ? container.querySelector(cssSelector) : container
        navigator.clipboard.writeText(targetContainer.innerText)
        .then(data => {
            toast("Item copied to clipboard")
        })
        
    }, [])
    
    
    return (
        <MDBCol xs={12} lg={8} className="px-3 order-2 order-lg-1">
            {
                !isViewOwner && remote_files.length === 0 && remote_text_data.length === 0 &&
                <div className="illustration-container d-flex justify-content-center align-items-center">
                    <Illustration.ByTheRoad className="illustration" />
                    <div>Waiting for data from host...</div>
                </div>
            }

            {
                isViewOwner && data?.length === 0 && selected_files.length === 0 &&
                <div className="illustration-container large d-flex justify-content-center align-items-center">
                    <div>
                        <Illustration.Empty className="illustration my-2" />
                        <div>You haven't shared any content yet</div>
                    </div>
                </div> 
            }

            {
                (isViewOwner ? selected_files : remote_files).length > 0 &&
                <div className={data?.length === 0 ? "file-list-container w-100 mb-4 grid mt-3 py-2" : "file-list-container w-100 mb-4 horizontal mt-3 py-2"}>
                    {
                        (isViewOwner ? selected_files : remote_files).map(file => <SelectedFilesItem file={file} />)
                    }
                </div>
            }

            {
                (isViewOwner ? data : remote_text_data)?.map(content => 
                    <TextShareItem 
                        content={content} 
                        delete={deleteItem}
                        copy={copyItem} 
                        sub_post={isViewOwner ? sub_post : remote_sub_post_data}
                    />
                )
            }
            
        </MDBCol>
    )
})

export default MainSection;