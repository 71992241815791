import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import CustUp, { TCustUp } from "@custup/react";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { setShowFileSelectUI, setShowTextEditor, updateSelectedFiles } from "../../shared/rdx-slice";
import Icons from "../../shared/icons";
import { getSessionName, socket_server } from "../../shared/functions";

const FileSelectUI = React.memo((props: any) => {
    const custup_ref = React.useRef<TCustUp | null>();
    const [fileCount, setFileCount] = React.useState<number>(0);
    const dispatch = useAppDispatch();
    const should_show = useAppSelector(state => state.main.show_file_select);

    const handleSend = React.useCallback(() => {
        const all_selected_files = custup_ref.current?.get_all_files();
        all_selected_files?.map((file: File) => dispatch(updateSelectedFiles({file, bs4: window.URL.createObjectURL(file)})));
        socket_server.emit('route-data', all_selected_files?.map(file => ({file, type: file.type, name: file.name, id: file.id})), {room_name: getSessionName()})
        dispatch(setShowFileSelectUI(false));
    }, [])

    React.useLayoutEffect(() => {
        document.onkeydown = (e) => {
            if (e.key.toLowerCase() == 'escape' && should_show) {
                dispatch(setShowFileSelectUI(false));
            }
        }
    }, [should_show])

    return (
        <MDBContainer fluid className="file-select-container">
            <div className="file-select-close-btn" onClick={() => dispatch(setShowFileSelectUI(false))}>
                <Icons.Close />
            </div>
            <MDBRow className="w-100 d-flex justify-content-center">
                <MDBCol xs={12} sm={12} md={8} lg={6} className="file-select-inner-cont">
                    <div className="h1 text-center mb-4">Select Files</div>
                    <CustUp 
                        id="first-example-instance" 
                        ref={custup_ref}
                        allowed_sources={['record_video', 'record_audio', 'record_screen', 'capture_image', 'link_source']}
                        allowed_tools={['added_files_count', 'add_file', 'clear_files']}
                        file_preview_animation_types={['zoomIn']}
                        default_styles_override={{
                            defaultUIUploadIconsContainer: ['custup_default_ui_icons_cont', true],
                            outerContainer: ['custup_outer_container', true]
                        }}
                        on={[
                            {
                                type: 'file.afterAdded',
                                callbackFn(e) {
                                    setFileCount(e.detail.count);
                                },
                            },
                            {
                                type: 'file.removed',
                                callbackFn(e) {
                                    setFileCount(e.detail.files_count);
                                },
                            },
                            {
                                type: 'file.all_removed',
                                callbackFn(e) {
                                    setFileCount(0);   
                                },
                            }
                        ]}
                    />
                    <div className="file-select-buttons-cont">
                        <MDBBtn onClick={handleSend} disabled={fileCount === 0}>Share</MDBBtn>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
});

export default FileSelectUI;