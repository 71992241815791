import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import Icons from "../../shared/icons";
import { setShowLinkShare } from "../../shared/rdx-slice";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { getSessionName } from "../../shared/functions";
import { toast } from "react-toastify";
import QRCode from "react-qr-code";

const LinkShare = React.memo((props: any) => {
    const dispatch = useAppDispatch();
    const isViewOwner = useAppSelector(state => state.main.isViewOwner);
    const linkInputRef = React.useRef<HTMLInputElement | null>(null)

    const handleCopyLink = React.useCallback((input_click?: boolean) => {
        if (!linkInputRef.current?.value) return;
        linkInputRef.current!.select();
        navigator.clipboard.writeText(linkInputRef.current!.value);
        toast("Link copied!")
    }, [linkInputRef.current])

    return (
        <MDBContainer fluid className="link-share-container d-flex justify-content-center align-items-center">
            <div className="file-select-close-btn" onClick={() => dispatch(setShowLinkShare(false))}>
                <Icons.Close />
            </div>

            <MDBRow className="w-100 d-flex justify-content-center align-items-center">
                <MDBCol xs={12} md={8} lg={6} className="link-share-inner">
                    <div className="h4 text-center mb-3">Share Link</div>
                    <div className="share-input-container d-flex mb-4">
                        <input ref={linkInputRef} type="text" onClick={() => handleCopyLink(true)} 
                            className="form-control" contentEditable={false}
                            value={isViewOwner ? window.location.href + "?sid=" + getSessionName() : window.location.href}  />
                        <MDBBtn onClick={() => handleCopyLink()}><Icons.Copy /></MDBBtn>
                    </div>

                    <div className="d-block">
                        <div className="w-100 text-center mb-4">OR Scan QR code</div>
                        <div className="w-100 d-flex justify-content-center">
                            <QRCode value={isViewOwner ? window.location.href + "?sid=" + getSessionName() : window.location.href} />

                        </div>
                    </div>
                    
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
});

export default LinkShare;