import React from "react"
import Icons from "../../shared/icons";
import { convertFileSize, download_file, socket_server } from "../../shared/functions";
import { useAppDispatch, useAppSelector } from "../../shared/hooks";
import { clearSelectedFiles, setShowFilePreviewer, updateSelectedFiles } from "../../shared/rdx-slice";
import { TSelectedFile } from "../../shared/types";

const SelectedFilesItem = React.memo((props:any) => {
    const dispatch = useAppDispatch();
    const selected_files = useAppSelector(state => state.main.selected_files);
    const isViewOwner = useAppSelector(state => state.main.isViewOwner)

    const handleShowFilePreviewer = React.useCallback((file: File) => {
        dispatch(setShowFilePreviewer({status: true, file: file}))
    }, []);

    const handleRemoveFile = React.useCallback((file: File & {id: string}) => {
        const splice_file_out = selected_files.filter((f) => f.file['id' as unknown as  keyof File] != file.id);
        dispatch(clearSelectedFiles());
        splice_file_out.reverse().map(file => dispatch(updateSelectedFiles(file))); 
        socket_server.emit("item-deleted", {type: 'file', id: file.id})
    }, [selected_files]);

    if (props.file.file.type.split('/')[0] == 'image') {
        return <div key={Math.floor(Math.random() * 999999999999)}>
                    <img src={props.file.bs4 as string} />
                    <div className="display-btn">
                        <Icons.Eye className="svg-cont" onClick={() => handleShowFilePreviewer(props.file.file)} />
                        <Icons.Download onClick={() => download_file(props.file.file)} className="svg-cont" />
                    </div>
                    {
                        isViewOwner &&
                        <div className="remove-btn">
                            <Icons.Remove className="svg-cont" onClick={() => handleRemoveFile(props.file.file)} />
                        </div>
                    }
                    <div className="file-details">
                        <div>{props.file.file.name}</div>
                        <div>{convertFileSize(props.file.file.size)}</div>
                    </div>
                </div>
    }else if (props.file.file.type.split('/')[0] == 'video') {
        return <div key={Math.floor(Math.random() * 999999999999)}>
                    <video playsInline={false} muted={true} controls={false}>
                        <source src={props.file.bs4 as string} type="video/mp4" />
                        <source src={props.file.bs4 as string} type="video/webm" />
                        <source src={props.file.bs4 as string} type="video/ogg" />
                    </video>
                    <div className="display-btn">
                        <Icons.Play className="svg-cont" onClick={() => handleShowFilePreviewer(props.file.file)} />
                        <Icons.Download onClick={() => download_file(props.file.file)} className="svg-cont" />
                    </div>
                    {
                        isViewOwner &&
                        <div className="remove-btn">
                            <Icons.Remove className="svg-cont" onClick={() => handleRemoveFile(props.file.file)} />
                        </div>
                    }
                    <div className="file-details">
                        <div>{props.file.file.name}</div>
                        <div>{convertFileSize(props.file.file.size)}</div>
                    </div>
                </div>            
    }else if (props.file.file.type.split('/')[0] == 'audio') {
        return <div key={Math.floor(Math.random() * 999999999999)}>
                    <Icons.Audio className="svg-cont" />
                    <div className="display-btn">
                        <Icons.Play className="svg-cont" onClick={() => handleShowFilePreviewer(props.file.file)} />
                        <Icons.Download onClick={() => download_file(props.file.file)} className="svg-cont" />
                    </div>
                    {
                        isViewOwner &&
                        <div className="remove-btn">
                            <Icons.Remove className="svg-cont" onClick={() => handleRemoveFile(props.file.file)} />
                        </div>
                    }
                    <div className="file-details">
                        <div>{props.file.file.name}</div>
                        <div>{convertFileSize(props.file.file.size)}</div>
                    </div>
                </div>            
    }else if (props.file.file.type.split('/')[1] == 'pdf') {
        return <div key={Math.floor(Math.random() * 999999999999)}>
                    <Icons.PDF className="svg-cont" />
                    <div className="display-btn">
                        <Icons.Eye className="svg-cont" onClick={() => handleShowFilePreviewer(props.file.file)} />
                        <Icons.Download onClick={() => download_file(props.file.file)} className="svg-cont" />
                    </div>
                    {
                        isViewOwner &&
                        <div className="remove-btn">
                            <Icons.Remove className="svg-cont" onClick={() => handleRemoveFile(props.file.file)} />
                        </div>
                    }
                    <div className="file-details">
                        <div>{props.file.file.name}</div>
                        <div>{convertFileSize(props.file.file.size)}</div>
                    </div>
                </div>            
    }else{
        return <div key={Math.floor(Math.random() * 999999999999)}>
                    <Icons.File className="svg-cont" />
                    <div className="display-btn">
                        <Icons.Download onClick={() => download_file(props.file.file)} className="svg-cont" />
                    </div>
                    {
                        isViewOwner &&
                        <div className="remove-btn">
                            <Icons.Remove className="svg-cont" onClick={() => handleRemoveFile(props.file.file)} />
                        </div>
                    }
                    <div className="file-details">
                        <div>{props.file.file.name}</div>
                        <div>{convertFileSize(props.file.file.size)}</div>
                    </div>
                </div>
    }
});

export default SelectedFilesItem;